import logo from './logo.svg';
import './App.css';
import { Telephone, Envelope } from 'react-bootstrap-icons';
import BG from './Images/BG.jpg'

function App() {
    return (
        <div className="App" style={{ backgroundImage: `url(${BG})` }}>
            <div className='darken'>
                <h1>Nicholas Frazzica</h1>               
                {/* <h2>Finance & Mortgage Broking Services</h2> */}
                <h3>Coming Soon</h3>
                <footer>
                    <div className='footer-content left'>
                        <Telephone size={36}/>
                        <h4>0429 913 757</h4>
                    </div>
                    <div className='footer-content right'>
                        <Envelope size={36} />
                        <h4>nicholas@nicholasfrazzica.com.au</h4>
                    </div> 
                </footer>
            </div>   
        </div>
    );
}

export default App;
